let number_tabber_links = document.querySelectorAll('.number-tabber--actions li');

number_tabber_links.forEach((el, index) => {
    el.addEventListener( 'click', number_tabber_event );
});

function number_tabber_event(e) {

    e.preventDefault();

    let el       = e.currentTarget;
    let tabber   = window.helpers.parents(el, '.number-tabber')[0];
    let target   = el.getAttribute('data-target');
    let tab      = document.querySelector('.number-tabber--tabs ' + target);
    let tabs_sel = tabber.querySelectorAll(`:scope ${'.number-tabber--actions li'}`);
    let tabs     = tabber.querySelectorAll(`:scope ${'.number-tabber--item'}`);
    
    tabs_sel.forEach((tabbed_links_el, index) => {
        tabbed_links_el.classList.remove('active');
    });

    tabs.forEach((tabbed_content_el, index) => {
        window.helpers.hide(tabbed_content_el);
    });

    el.classList.add('active');
    
    window.helpers.fadeIn(tab, 'block');

}